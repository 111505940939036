.incident-card {
    &__info {
        display: flex; 
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 1em;
    }

    &__status {
        display: flex;
        flex-wrap: wrap;
        color: black;
        white-space: nowrap;
        align-self: center;

        span {
            font-weight: bold;
        }
    }
    .date-time--mobile {
        display: flex;
        justify-content: flex-end;
        color: black;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 70%;
        
        .image-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            img {
                width: 100%;
                max-width: 100%;
                max-height: 100%;
            }
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
        .date-time {
            display: flex;
            width: 100%;
            color: black;
        }
    }
    &__footer {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .button-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            &--mobile {
                justify-content: center;
            }
            
            &--tablet {
                justify-content: center;
            }
        }
    }
}