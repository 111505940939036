.incident-modal {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    gap: 1rem;
    height: 100%;
    color: black;

    &__image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 auto;

        img {
            object-fit: contain;
            height: 100%;
            max-width: 100%;
        }
    }
}
