.history-page-layout {
    .table-responsive {
        height: 0px;
        flex: 1 1 auto;
    }

    thead {
        position: sticky;
        top: 0px;
        background-color: white;
    }

    .table tbody tr {
        cursor: pointer;
    }

    .row {
        align-items: center;
        column-gap: 5em;
        margin: 0;
        margin-top: 1.3rem;
    }

    .custom-col-1 {
        min-width: 100px;
        width: 160px;
    }

    .custom-col-2 {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 180px;
    }

    .alert {
        width: 100%;
        margin-top: 4px;
        margin-bottom: 0;
    }

    .fall-image {
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
}
