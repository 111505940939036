.dashboard {
    &__tabs {
        &__header {
            font-weight: 400;
            font-size: 1.2em;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2px;
            color: var(--secondary);
        }

        button {
            appearance: none;
            border: none;
            background-color: transparent;
            text-decoration: none;
        }

        .active {
            font-weight: 620 !important;
            color: black;
        }

        .non-active {
            background-color: #f4f4f4;
        }
    }
    
    &__subheader {
        font-weight: 450;
        font-size: max(0.7rem, 0.8vw);
    }

    &__queue-container {
        height: 0px;
        flex: 1 1 auto;
        margin-bottom: 0.5rem;

        &__card {
            border: 1px solid #c0c0c0;
        }
    }

    .box-shadow {
        &-red {
            box-shadow: 0px 3px 8px 1px #c0c0c0, -7px 0px 0px 0px red;
        }

        &-darkgray {
            box-shadow: 0px 3px 8px 1px #c0c0c0, -7px 0px 0px 0px darkgray;
        }
    }

    .overflow-y-scroll {
        overflow-x: hidden;
        overflow-y: scroll;
    }
}
