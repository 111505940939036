@import 'bootstrap';

.nav {
    @extend .navbar;

    &__icon {
        color: white;
        font-size: 1.5rem;
        margin-left: 1rem;

        &:hover {
            cursor: pointer;
        }
    }

    .dropdown {
        &-toggle {
            color: white;
            padding: 0;
        }

        &-menu {
            left: -0.5rem !important;
            min-width: 5rem;
        }
    }

    .bi-caret-down-fill {
        font-size: 1rem;
        margin-left: 0.3rem;
    }

    .bi-bell-notif {
        position: relative;
        left: -0.8rem;
    }
}
