.date-input {
    &--fluid {
        flex: 1 1 200px;
    }

    &__icon {
        position: relative;
        right: 30px;
        margin-right: -16px;
        cursor: pointer;
    }

    .form-control {
        cursor: pointer;
        flex: 1 1 200px;
        width: 200px;
    }

    .react-datepicker {
        &-wrapper {
            flex: 1 1 100%;
        }

        &__input-container {
            display: flex;
        }

        &__close-icon {
            display: none;
        }
    }

    .hideText {
        color: transparent;
    }
}
