@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import 'react-datepicker/dist/react-datepicker.css';

@import "variables";
@import "mixins";
@import "sb-admin-2.min";

html {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
}

.content{
    background-color: white;
    height:100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--primary);
}