.sidenav {
    background: #3a5cbf;
    padding: 0 1.5rem;
    flex-shrink: 0;

    &__icon {
        color: white;
        font-size: 1.8rem;
    }

    a,
    &__header {
        text-decoration: none;
        font-size: 1.2rem;
        padding: 0.5rem 0;
        color: lightgray;
        display: block;
    }

    a:hover {
        text-decoration: none;
        color: white;
    }

    li:hover {
        background-color: white;
    }

    .active > :first-child {
        color: white;
        font-weight: 500;
    }
}
