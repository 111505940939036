@import "bootstrap";

.login{
    &__behind-container {
            background-color: #486dda;
            height: 100%
    }

    &__header-icon {
        height: 10rem;

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__logo {
        width: 70%;
    }

    &__image {
        object-fit: contain;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        overflow-y: hidden;
    }

    &__container {
        @extend .container;
        background-color: white;
        border-radius: 10px;
        max-width: 1000px;
    }
    &__row{
        @extend .row;
    }

    &__col {
        @extend .col;
    }

    &__btn.btn-primary.btn-lg {
        @extend .btn, .btn-primary, .btn-lg;
        margin: 5% 10% 5% 10%;
        border-radius: 25px;
        width: 80%;
    }

    &__title {
        color: black;
        text-align: center;
    }
}
