.filter-controls {
    &__icon {
        display: flex;
        justify-content: end;
        font-size: 1.5em;
        cursor: pointer;
        position: relative;
        top: -2em;
        margin-bottom: -1.5em;
    }

    &__inputs-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 2em;
        row-gap: 5px;
    }
}