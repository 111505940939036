.incidentbuttonrow {
    white-space: nowrap;
    width: 100%;
    display: flex;
    gap: 0.5em;

    .btn-flex-0 {
        flex: 0;
        flex-grow: 0;
        flex-basis: 160px;
    }

    .btn-flex-1 {
        flex: 1;
        flex-grow: 1;
    }
}
