.lightbox {
    position: fixed;
    z-index: 1;
    border-radius: 15px;
    overflow: auto;
    background-color: rgba(255,255,255, 0.95);
    box-shadow: 0px 3px 5px 1px #c0c0c0, -4px 0px 0px 0px ghostwhite;

    &__close {
        position: absolute;
        top: 14px;
        right: 22px;
        cursor: pointer;
        font-size: 30px;
    }

    &--mobile {
        display: block;
        position: fixed;
        left: 0;
        top: 80px;
        width: 100%;
        height: calc(100% - 5rem);
        overflow: auto;
        background-color: darkgray;
        border-radius: 0;
    }

    &__content {
        width: 100%;
        height: 100%;
    }
}