.search-input {
    &--fluid {
        flex: 1 1 200px;
    }

    &__icon {
        position: relative;
        right: 30px;
        margin-right: -16px;
        cursor: pointer;
    }

    .form-control {
        flex: 1 1 200px;
        width: 200px;
    }

    ::-webkit-search-cancel-button {
        appearance: none;
    }
}
