.countdown {
    &--regular {
        width: 110px;
        height: 35px;
        border-radius: 25px;
        font-size: 1.2rem;
        min-width: 100px;
    }

    &--compact {
        width: 90px;
        height: 25px;
        border-radius: 15px;
        font-size: 1rem;
        min-width: 100px;
    }
}

.style {
    &--danger,
    &--timeout {
        background-color: var(--danger);
        color: var(--white);
    }

    &--timeout {
        opacity: 50%;
    }

    &--warning {
        background-color: var(--warning);
        color: var(--dark);
    }

    &--loading {
        background-color: rgb(207, 207, 207);
        color: rgb(207, 207, 207);
    }
}